/**
 * Lists
 *
 * Description: Some overrides for the Nifty lists.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - List group
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - List group
 * -----------------------------------------------------------------------------
 */

.list-group {
	&.list-group-invono {
		.list-group-item {
			padding: 0;
			margin-bottom: 3px;
			color: $greyDark;
			min-height: 50px;
			border: 0px;
			user-drag: none;
			user-select: none;
			-moz-user-select: none;
			-webkit-user-drag: none;
			-webkit-user-select: none;
			-ms-user-select: none;

			&:hover, &.active {
				background-color: shade($blueLight, 98%);
				border-color: transparent;
			}
			// &:not(.list-group-agenda-item).active {
			// 	background-color: shade($blueLight, 95%);
			// 	border-color: transparent;
			// }

			&:not(.list-group-agenda-item):focus {
			}

			> * {
				padding: 16px;
			}

			> .multilines {
				padding: 0;

				> a {
					display: block;
					padding: 8px 16px;
					height: 50px;
					overflow: hidden;

					&:hover {
						background-color: shade($blueLight, 98%);
					}

					&.active {
						background-color: shade($blueLight, 98%);
					}

					&:focus {
					}

					> p, .multilines__vmiddle > p {
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
						position: relative;
					}

					.multilines__vmiddle > p {
						padding-left: 25px;
					}

					.multilines__vmiddle {
						display: table-cell;
						vertical-align: middle;
						position: relative;
						height: 34px;

						.multilines__num {
							position: absolute;
							left: 0;
						}
					}
				}
			}

			.list-group-item--profile-image {
				float: left;
				height: 50px;
				width: 50px;
			}

			&.list-group-item--double-icon {
				> * {
					padding: 0;
					display: flex;
					align-items: center;
				}
			}

			&.list-group-item-narrow {
				> * {
					padding: 10px 16px;
				}
			}

			.icon-shares {
				width: 124px;
				margin-right: 3px;
				background-color: $invono-accent-color;
				color: $white;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px 10px 10px 16px;

				p {
					color: $white;
					margin: 0;
					padding: 0;
					display:flex;

					+ p {
						text-align: right;
					}
				}
			}

			.icon-expire {
				width: 85px;

				&.two-rows {
					padding-top: 9px;
					padding-bottom: 9px;
				}
			}

			.vertical-top {
				line-height: 1.3;
			}

			.bg-gray-dark {
				background-color: $greyLight;
			}
		}

		&.list-group-item-dragging {
			z-index: 10000;
			cursor: move;

			a {
				cursor: move;
			}
		}

		.list-group-item-level-two {
			padding-left: 50px;
			/*max-height: 0;
    		transition: max-height 0.15s ease-out;
    		overflow: hidden;*/
		}

		/*& .list-group-item-arrow:hover + .list-group-item-level-two,
		& .list-group-item-arrow:hover + .list-group-item-level-two + .list-group-item-level-two {
			max-height: 500px;
    		transition: max-height 0.25s ease-in;
		}*/
		// & .list-group-item + .list-group-item-level-two,
		// & .list-group-item + .list-group-item-level-two + .list-group-item-level-two {
		// 	display: none;
		// }

		.infoLeft {
			width: 50px;
			height: 50px;
			line-height: 50px;
			color: white;
			text-align: center;
			width: 50px;

			&--red {
				background-color: #D51D00;
			}

			&--green {
				background-color: #00B651;
			}

			&--grey {
				background-color: #dadada;
			}

			&.infoLeft--mins {
				a.dropdown-toggle {
					line-height: 15px;
					padding-top: 12px;

					> span {
						display: inline-block;
						width: 100%;
						border-right: 1px solid #dadada;
						color: $font-color-mid;
					}
				}
			}

			&.infoLeft--category {
				width: 100px;
				background-color: #dadada;
				line-height: normal;
				padding-top: 7px;
				color: darken($white, 50%);
			}

			&.dropdown {
				width: auto;
				height: auto;
				line-height: normal;
			}

			i {
				font-size: 150%;
			}

			p {
				font-size: 100%;
			}

			a.dropdown-toggle {
				display: block;
				width: 50px;
				height: 50px;
				line-height: 50px;
				color: white;
				text-align: center;
			}
		}

		.dragHandler {
			width: auto;
			height: 50px;
			line-height: 50px;
			color: white;
			text-align: center;
			padding: 0 4px;
			background-color: white;

			.fa {
				color: #ccc;
				font-size: em(8px);
			}

			&.disabled {
				cursor: default;

				.fa {
					color: #fff;
				}
			}
		}

	}

	/*.list-item-add-sub-proposal {
		display: none;
	}*/

	/*.active + .list-item-add-sub-proposal, .active + .list-group-item-level-two + .list-item-add-sub-proposal {
		display: block;
	}*/
	.list-group-item.active + .list-group-item-level-two,
	.list-group-item.active + .list-group-item-level-two + .list-group-item-level-two,
	.list-group-item.open + .list-group-item-level-two,
	.list-group-item.open + .list-group-item-level-two + .list-group-item-level-two {
		display: block;
	}

	.list-group-item-arrow {
		position: relative;
		width: 100%;

		&:hover:before, &.active:before {
			color: $white;
		}

		&:before {
			position: absolute;
			top: 50%;
			right: 12px;
			font-size: 24px;
			margin-top: -12px;
			@extend .fa;
			@extend .fa-angle-right;
		}

		&.has-children {
			&:before {
				@extend .fa-angle-down;
			}

			// &.active:before,
			// &.open:before {
			// 	@extend .fa-angle-up;
			// }
		}
	}

	.bg-green {
		background-color: $green;
	}

	.color-white {
		color: white;
	}

	&.list-group-invono-small .list-group-item > * {
		padding: 8px;
	}

	&.list-group-invono--bordered {
		.list-group-item {
			border: 1px solid $greyLight;

			&:hover, &.active {
				background-color: inherit;
				border-color: $greyLight;
			}

			> * {
				padding: 8px;
			}
		}
	}
}
