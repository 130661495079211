.text {
	font-size: 13px;
	font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased !important;
	font-weight: normal;
  color: $font-color-mid;

	&--field-header {

	}

	&--link {
		text-decoration: underline;
		cursor: pointer;

		&:hover {
			color: $font-color-mid;
		}
	}

	&--muted {
		color: $font-color-light;
	}

	&--disabled {
		color: #cccccc !important
	}

	&--bold {
		font-weight: bold;
	}

	&--brand {
		line-height: 40px;
		color: #fff;
		font-size: 18px;
		font-weight: 600;
	}

	&--company-name {
		line-height: 30px;
		color: #fff !important;
		font-size: 16px;
		font-weight: 600;
		word-break: word-break;
		cursor: pointer;
	}

	&--menu {
		display: block;
		color: $font-color-light;

		&--active, &:hover, &:focus, &:active {
			color: $font-color-white;
		}
	}

	&--menu__disabled {
		display: block;
		color: $font-color-light;
		opacity: 0.5;
	}

	&--align-left {
		text-align: left;
	}

	&--align-right {
		text-align: right;
	}

	&--align-center {
		text-align: center;
	}

	&--username {

	}

	&--invono {
		color: $font-color-invono;
	}

	&--md {
		font-size: 16px;
	}

	&--xl {
		font-size: 32px;
	}

	&--error {
		color: $font-color-error;
	}

	&--success {
		color: $font-color-success;
	}

	&--headline {
		font-weight: bold;
	}

	&--sort {
		color: $font-color-mid;
		cursor: pointer;
	}

	&--muted-sort {
		color: $font-color-light;
		cursor: pointer;
	}

	&--padded {
		padding: 30px 30px 0;
	}

	&--no-wrap {
		white-space: nowrap;
	}

	&--wiki {
		padding: 35px 50px;
	}

	&--lg-icon {
		font-size: 150%;
		line-height: 50px;
	}

	&--agreement {
		color: $invono-blue;
		text-decoration: underline;
	}
}
