/**
 * Toggle Switch
 *
 * Description: Some extensions for the Nifty Toggle Switch.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Toggle Switch with text
 * 2.0 - Toggle Switch always active color
 * 3.0 - Toggle Switch in page-header
 * 4.0 - Toggle Switch description
 * 5.0 - Toggle Switch label
 * 6.0 - Toggle Switch with button
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Toggle Switch with text
 * -----------------------------------------------------------------------------
 */

.toggle-switch.toggle-switch--text {
	+ label {
		height: $toggle-switch--text-container-height;
		padding-left: $toggle-switch--text-container-width + $toggle-switch-label-margin-left;
		line-height: $toggle-switch--text-container-height;
		margin-bottom: 6px;

		&:empty{
			padding-left: $toggle-switch--text-container-width;
		}

		// Container
		&:before {
			width: $toggle-switch--text-container-width;
			height: $toggle-switch--text-container-height;
			border-radius: $toggle-switch--text-container-height / 2;
			content: attr(data-switch-label);
			padding-right: 0;
			padding-left: 18px;
			text-align: center;
			color: $white;
		}

		// Button
		&:after {
			width: $toggle-switch--text-container-height - ($toggle-switch-button-margin * 2);
			height: $toggle-switch--text-container-height - ($toggle-switch-button-margin * 2);
			border-radius: $toggle-switch--text-container-height;
		}
	}

	&:checked {
		+ label {
			// Container
			&:before {
				padding-left: 0;
				padding-right: 18px;
			}

			// Button
			&:after {
				left: $toggle-switch--text-container-width - ($toggle-switch--text-container-height - ($toggle-switch-button-margin * 2)) - $toggle-switch-button-margin;
			}

			&[data-switch-label-checked] {
				&:before {
					content: attr(data-switch-label-checked);
				}
			}
		}
	}

	&.toggle-switch--medium {
		$extraWidth: 15px;

		+ label {
			&:before {
				width: $toggle-switch--text-container-width + $extraWidth;
			}
		}
		&:checked {
			+ label {
				// Button
				&:after {
					left: ($toggle-switch--text-container-width - ($toggle-switch--text-container-height - ($toggle-switch-button-margin * 2)) - $toggle-switch-button-margin) + $extraWidth;
				}
			}
		}
	}

	&.toggle-switch--large {
		$extraWidth: 55px;

		+ label {
			padding-left: $toggle-switch--text-container-width + $extraWidth;

			&:before {
				width: $toggle-switch--text-container-width + $extraWidth;
			}
		}
		&:checked {
			+ label {
				// Button
				&:after {
					left: ($toggle-switch--text-container-width - ($toggle-switch--text-container-height - ($toggle-switch-button-margin * 2)) - $toggle-switch-button-margin) + $extraWidth;
				}
			}
		}
	}

	&.toggle-switch--small {
		$toggle-switch--text-container-width: 45px;

		+ label {
			padding-left: $toggle-switch--text-container-width;

			&:before {
				width: $toggle-switch--text-container-width;
			}
		}
		&:checked {
			+ label {
				// Button
				&:after {
					left: ($toggle-switch--text-container-width - ($toggle-switch--text-container-height - ($toggle-switch-button-margin * 2)) - $toggle-switch-button-margin);
				}
			}
		}
	}
}



/**
 * 2.0 - Toggle Switch always active color
 * -----------------------------------------------------------------------------
 */

.toggle-switch.toggle-switch--always-active-color {
	+ label {
		&:before {
			background-color: $toggle-switch-container-bg-checked;
		}
	}
}



/**
 * 3.0 - Toggle Switch in page-header
 * -----------------------------------------------------------------------------
 */

@media (min-width: 768px){
	.toggle-switch--in-page-header {
		position: absolute;
		right: 300px;
		top: 30px;
	}
}



/**
 * 4.0 - Toggle Switch description
 * -----------------------------------------------------------------------------
 */
.toggle-switch-description {
	display: inline-block;
	padding-right: 10px;
	vertical-align: middle;
}



/**
 * 5.0 - Toggle Switch label
 * -----------------------------------------------------------------------------
 */
 .toggle-switch-label {
	 display: inline-block;
	 margin-right: 4px;
	 overflow: hidden;
	 position: relative;
	 top: 1px;
 }



 /**
  * 6.0 - Toggle Switch with button
  * -----------------------------------------------------------------------------
  */

  .toggle-switch-with-button + label {
	  z-index: 10;
  }
