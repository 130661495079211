.share-overview {

	&__table {
		display: flex;
		flex-direction: row;

		&__share-types {

			flex: 1;
		}

		&__sequences {
			flex: 1;
		}
	}

	&__class-of-share {
		width: 120px;
	}

	&__numOfShares {
		width: 100px;
	}
	&__votes {
		width: 100px;
	}

	&__shareholder {
		width: 80px;
		flex: 1;
	}

	&__id {
		width: 120px;
	}

	&__sequence {
		width: 140px;
	}

	&__date-from {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		justify-content: flex-end;
	}

	&__mar-text {
		margin-right: 20px;
	}

	&__general-info {
		border-bottom: 1px solid #ddd;
		height: 35px;
		padding-left: 10px;
	}


}
