/**
 * Layout Utility classes
 *
 * Description: Classes to reset/change margin/paddings.
 * Version: 1.0.3
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Margin
 * 2.0 - Padding
 * 3.0 - Float
 * 4.0 - Misc
 * -----------------------------------------------------------------------------
 */



/**
 * 1.0 - Margin
 * -----------------------------------------------------------------------------
 */

.margin-all-0 { margin: 0 !important; }
.margin-top-0 { margin-top: 0 !important; }
.margin-right-0 { margin-right: 0 !important; }
.margin-bottom-0 { margin-bottom: 0 !important; }
.margin-left-0 { margin-left: 0 !important; }
.margin-horizontal-0 { margin-right: 0 !important; margin-left: 0 !important; }
.margin-vertical-0 { margin-top: 0 !important; margin-bottom: 0 !important; }
.margin-bottom-std { margin-bottom: $margin-standard !important; }
.margin-top-half { margin-top: $margin-standard / 2; }

.mar-btm-2x { margin-bottom: 30px; }
.mar-btm-20 { margin-bottom: 20px; }
.mar-btm-0 { margin-bottom: 0; }
.mar-btm-quarter, .mar-btm-half { margin-bottom: 7px; }
.mar-ver-2x { margin-top: 30px; margin-bottom: 30px;}
.mar-lft-half { margin-left: 7px; }
.mar-top-half-half { margin-top: 2px; }
.mar-rgt-half { margin-right: 7px; }
.mar-top-2x { margin-top: 30px; }
.mar-top-20 { margin-top: 20px; }
.mar-rgt-half-half { margin-right: 2px; }

@media (max-width: 991px) {
	.mar-btm-device-medium {
		margin-bottom: 15px;
	}
}



/**
 * 2.0 - Padding
 * -----------------------------------------------------------------------------
 */

.padding-all-0 { padding: 0 !important; }
.padding-top-0 { padding-top: 0 !important; }
.padding-right-0 { padding-right: 0 !important; }
.padding-bottom-0 { padding-bottom: 0 !important; }
.padding-left-0 { padding-left: 0 !important; }
.padding-horizontal-0 { padding-left: 0 !important; padding-right: 0 !important; }
.padding-vertical-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
.padding-top-x2 { padding-top: 30px; }
.padding-hor-45 { padding-left: 55px; padding-right: 55px; }

.pad-rgt-half { padding-right: 7px; }
.pad-lft-half { padding-right: 7px; }
.pad-btm-third { padding-bottom: 5px; }
.pad-top-half { padding-top: 7px; }

.pad-no-but-right {
	padding: 0 5px 0 0 !important;
}

.pad-no-but-left {
	padding: 0 0 0 5px !important;
}


/**
 * 3.0 - Float
 * -----------------------------------------------------------------------------
 */

.layout-right {
	float: right;
}

.layout-left {
	float: left;
}


/**
 * 4.0 - Misc
 * -----------------------------------------------------------------------------
 */

.is-hidden {
	display: none !important;
}

.vertical-top {
	vertical-align: top;
}

.pointer {
	cursor: pointer;
}

.overflow-visible {
	overflow: visible !important;
}

.min-width-75 {
	min-width: 75px;
}

.image-size-medium {
	width: 137px;
}

.row-inline-block {
	word-spacing: -0.36em;

	> * {
		word-spacing: normal;
	}

	.col-inline-block {
		display: inline-block;
		vertical-align: top;
		float: none;
	}
}

.layout-center {
	display: block;
	margin: auto;
}

.display-block {
	display: block;
}

.company-search {
	float: none;
	padding-top: 5px;
}

.close-icon-with-text {

	span {
		margin-right: 2px;
	}

	.pci-cross {
		display: inline-block;
		vertical-align: middle;
	}
}

.overflow-visible {
	overflow: visible;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.border-bottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

table.table--vert-mid {
	> tbody {
		> tr {
			> td {
				vertical-align: middle;
			}
		}
	}
}
