.split {
	&__row {
		background-color: $table-color-dark;
		padding: 10px;
		display: flex;
		flex-direction: row;

		@media screen and (max-width: 1250px) {
			flex-direction: column;
		}

	}

	&__input {
		display: flex;
		flex: 1 1 100%;
	}

	&__button-group {
		display: flex;

		.btn:nth-last-child(1) {
			margin-left: 5px;
		}
	}
}


