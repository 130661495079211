/**
 * Flag
 *
 * Description: Works like the Media component, but can vertically align stuff too.
 * Version: 1.1.1
 * -----------------------------------------------------------------------------
 */


.flag {
	display: table;
	width: 100%;

	.flag-image, .flag-body {
		display: table-cell;
		vertical-align: top;
	}

	.flag-image {
		padding-right: 1em;

		> img {
			display: block;
			max-width: none;
		}

		&.flag-image-flipped  {
			padding-right: 0;
			padding-left: 1em;
		}
	}

	.flag-body {
		width: 100%;
	}

	&.flag-middle {
		.flag-image, .flag-body {
			vertical-align: middle;
		}
	}

	&.flag-bottom {
		.flag-image, .flag-body {
			vertical-align: bottom;
		}
	}
}