/**
 * Alert
 *
 * Description: Some overrides for the Nifty/Bootstrap alerts.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Alert
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Alert
 * -----------------------------------------------------------------------------
 */

.alert {
	// display: flex;

	&--unset-margin {
		> p {
			margin-bottom: unset;
		}
	}

	.alert-message {
		max-width: 400px;
		word-wrap: break-word;
	}

	&.alert-status {
		margin-bottom: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		height: 56px;
		min-width: 330px;
	}

	&.alert--icon {
		position: relative;
		min-height: 66px;
		display: flex;

		.icon-container {
			position: absolute;
			top: 19px;

			.fa {
				font-size: 30px;
			}
		}

		.alert-text {
			padding-left: 45px;
			align-self: center;
		}

		&.alert--icon-sm {
			//padding-top: 23px;
		}
	}

	&.dashboard-alert {
		min-height: 40px;
		margin-bottom: 16px;
	}

	&.alert-invono {
		@include alert-variant($white, $invono-accent-color);
	}

	&.alert-warning{
		background-color: $warning-bg;
	    border-color: transparent;
	    border-left: 3px solid desaturate(darken($warning-bg, 15%), 3%);
	    color: $warning-color;
	    .close, .alert-link{
	        color: $warning-color
	    }
    }
}
