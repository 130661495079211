/**
 * Panel
 *
 * Description: Some overrides for the Nifty panels.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Panel
 * 2.0 - Dashboard Panel
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Panel
 * -----------------------------------------------------------------------------
 */

.panel {
	box-shadow: none;
	-webkit-box-shadow: none;
	border: 0;

	.panel-body.narrow {
		padding: 9px 9px 9px 9px;
	}

	.panel-heading {
		border-bottom: 1px solid rgba(0, 0, 0, 0.07);

		&.panel-heading--no-border {
			border-bottom: 0;

			&:after {
				border-bottom: 0;
			}
		}

		.panel-label {
			float: right;
			background-color: #EAF0F5;
			border-radius: 6px;
			padding: 0 10px;
			height: 29px;
			line-height: 29px;
			margin-top: 11px;

			.btn {
				background-color: #EAF0F5;
				border-radius: 6px;
				padding: 0 10px;
				height: 29px;
				line-height: 29px;
				margin-top: 11px;
				border: 0 !important;
				font-size: 1em;
				font-weight: 600;
				color: #6c6c6c;
			}
		}
		.panel-label.btn-group {
			background-color: transparent;
			border-radius: 0;
			padding: 0;
			height: auto;
			line-height: inherit;
			margin-top: 0;
		}
	}

	&.panel--no-color {
		background-color: inherit;
		border: none;
		box-shadow: none;
		-webkit-box-shadow: none;

		&.border {
			border: 1px solid rgba(0, 0, 0, 0.07);
		}

	}

	&.panel--with-button {
		.panel-body {
			padding-bottom: 15px;
		}
	}

	.panel-bordered-grey {
		border: 1px solid darken($border-color-base, 15%);
	}

	.panel-buttons {
		padding: 8px 15px;
		text-align: right;

		.btn {
			margin-left: 5px;
		}
	}

	.panel-body.row {
		margin-left: -15px;
		margin-right: -15px;
	}

	> .panel-heading:after {
		border-bottom: 0;
	}

	.panel-control .btn-default {
		@include btn-variant($light-color, $light-bg);
		border-width: 1px;
		border-style: solid;
	}

	.panel-control .Select {
		display: inline-block;
		margin-top: 6px;
		min-width: 200px;
	}

	&.panel-blue {
		@include panel-variant($body-color, $blueLight);
	}

	&.panel-transparent {
		@include panel-variant($body-color, transparent);
	}
}

.alert-border {
	border: 2px solid rgba(0, 0, 0, 0.07);
	border-radius: 15px;
}


/**
 * 2.0 - Dashboard Panel
 * -----------------------------------------------------------------------------
 */

.panel-dashboard {
	min-height: 372px;
	
	.panel-body {
		height: 245px;
		padding-bottom: 0;
	}

	.panel-footer {
		@include clearfix();
		min-height: 65px;
		padding: 10px 28px 25px;
		background-color: $white;
		border: none;
	}

	&.panel-my-investments {
		> .panel-body {
			height: 380px;
		}
	}

	&.panel-my-accounts {
		> .panel-body {
			height: auto;
			padding-bottom: 15px;
		}
	}
}
