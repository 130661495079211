html, body{
    min-width: 290px;
    -ms-overflow-style: scrollbar
}
body{
    background-color: $body-bg;
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: normal;
    color: $body-color;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
}


::selection{
    background-color : $selection-bg;
    color : $selection-color;
}

::-moz-selection{
    background-color : $selection-bg;
    color : $selection-color;
}
