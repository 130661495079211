/**
 * Form
 *
 * Description: Style for forms and inputs.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Form control (overrride)
 * 2.0 - Form group
 * 3.0 - Datepicker
 * 4.0 - React Select
 * 5.0 - React Timepicker
 * 6.0 - Dropzone
 *   6.1 - Dropzone preview
 * 7.0 - File group
 * 8.0 - React numeric input
 * 9.0 - Textarea
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Form control (overrride)
 * -----------------------------------------------------------------------------
 */

.form-control {
	 border-color: darken($border-color-base, 15%);
	 padding: 8px 12px;
	 color: $font-color-mid;

	 &.Select {
		 background-color: transparent;
	 }

	 &:not([type="checkbox"]), &:not([type="radio"]) {
		 min-height: 36px;
	 }
}



/**
 * 2.0 - Form group
 * -----------------------------------------------------------------------------
 */

.form-group {
	&.form-group-no-padding {
		padding: 0;
	}
}

/**
 * 4.0 - React Select
 * -----------------------------------------------------------------------------
 */

 .Select {
	 border: 0;
	 padding: 0;

	.Select-control {
		 border-radius: 0;

		.has-error & {
			border-color: #a94442;
		}
	 }

	 &.is-disabled > .Select-control {
		 background-color: #eee !important;
	 }
 }



/**
 * 6.1 - Dropzone preview
 * -----------------------------------------------------------------------------
 */

.dropzone-file-preview {
	width: 50px;
	margin-right: 10px;
}

.dropzone-file-preview-lg {
	max-width: 100%;
}



/**
 * 7.0 - File group
 * -----------------------------------------------------------------------------
 */

 .file-group {
 	// padding-top: 20px;

 	&.file-group--narrow {
 		padding-top: 5px;
 		margin-bottom: 0px;
 	}

 	.file-item {
 		height: 50px;
 		padding: 5px;
 		background-color: $white;

 		p {
 			line-height: 40px;
 			padding-left: 10px;
 			vertical-align: middle;
			margin-bottom: 0;

 			> .btn {
 				margin-top: -3px;
 			}
 		}

 		&.uploaded-file-item {
			padding: 0 10px;
 			background-color: $uploaded-file-bg-color;
 			border: 1px solid $uploaded-file-border-color;

 			> * {
 				padding-top: 5px;
 				padding-bottom: 5px;
 			}

 			.file-icon {
 				padding-right: 10px;
 				border-right: 1px solid $uploaded-file-border-color;
 				height: 50px;
 				line-height: 50px;

 				> .fa {
 					font-size: 30px;
 				}
 			}

 			p {
 				&.file-info {
 					line-height: normal;
 				}
 			}
 		}
 	}

 }


/**
 * 8.0 - React numeric input
 * -----------------------------------------------------------------------------
 */

.react-numeric-input {
	b {
		display: none !important;
	}
}


/**
 * 9.0 - Textarea
 * -----------------------------------------------------------------------------
 */
textarea {
	resize: none !important;
}

.textarea-static {
	&.textarea-static--readonly {
		min-height: 108px;
		background-color: #eee;
		border: 1px solid #c3c3c3;
		padding: 8px 12px;
		cursor: not-allowed;
	}
}
