.permissions-editor {
	display: flex;
	flex: 1;
	flex-direction: column;

	&__tags {
		display: flex;
		flex: 1;
		flex-direction: column;
		margin-bottom: 15px;

		&__header {
			display: flex;
			flex: 1;
			flex-direction: row;
			justify-content: space-between;
			padding-bottom: 4px;

			&__text {
				display: flex;
				flex-direction: row;
			}

			&__action {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
			}


		}
	}

	&__link {
		margin-right: 12px;
	}
}
