.dropzone {
	&--active {
		@extend .dropzone:hover;
		border-color: #54ABD9;
	}

	&--small {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		min-height: 36px;
		padding: 0px;
		font-size: 0.9em;
		color: $invono-grey;
		cursor: pointer;
		border: 1px dashed rgba(0, 0, 0, 0.2);
		background-color: #ecf0f5;

		p {
			margin: 0;
		}
	}

	&__box {
		display: flex;
		flex-direction: column;
		height: 125px;
		justify-content: space-evenly;
	}

	&__content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 8px;

		&--icon {
			display: flex;
		}
	}

	&__icon {
		margin-right: 8px;
	}
}
