.agenda-attendees-component {
	.attendees-row {
		margin-bottom: 5px;

		.attendees-row--left, .attendees-row--right {
			width: 100px;
		}

		.attendees-row--left img {
			height: 50px;
			width: 50px;
		}

		.attendees-row--remove {
			// margin: 9px;
			margin: 7px 9px 0;
			width: auto;
			height: 43px;
			vertical-align: top;
		}

		.attendees-row--input {
			padding: 7px 9px 9px 0;

			&.pad-lft {
				padding-left: 9px;
			}
		}

		.attendees-row--status {
			width: 50px;
			height: 50px;

			&--red {
				background-color: $danger-bg;
			}

			&--green {
				background-color: $success-bg;
			}

			&--grey {
				background-color: #E5E5E5;
			}

			a.dropdown-toggle, i {
				line-height: 50px;
				text-align: center;
				width: 50px;
				font-size: 150%;
			}

			a.dropdown-toggle {
				color: white;
				display: inline-block;
			}

			i {
				color: white;
			}

		}

		.attendees-row--notified {
			width: 50px;
			height: 50px;

			i {
				line-height: 50px;
				text-align: center;
				width: 50px;
				font-size: 150%;
			}
		}

		p {
			line-height: 50px;
		}
	}
}

.attendees-alert-wrapper {
	margin-top: 10px;

	.alert {
		margin-bottom: 10px;
		min-height: 66px;
	}
}
