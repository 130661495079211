.table {
	> tbody {
		> tr {
			> td {
				height: 47px;
			}
		}
	}

	.account-company {
		width: 20%;
	}

	.account-type {
		width: 15%;
	}

	.account-access {
		width: 20%;
	}

	.account-status {
		width: 35%;
	}

	caption {
		font-size: 16px;
		font-weight: bold;
		padding-bottom: 10px;
		border-bottom: 1px solid $table-border-color;
		margin-bottom: 10px;
	}
}
