.show-more-box {
	position: relative;

	&__content {
		position: relative;
		overflow: hidden;
	}

	&__content--closed {
		max-height: 190px;
	}

	&__content--open {
		max-height: unset;
	}

	&__button {
		position: relative;
		height: 1px;
		border-top: 1px dashed $textbox-border-color;
	}

	&__button--closed {
		margin-top: 10px;
	}

	&__button--open {
		margin-top: 40px;
	}

	&__button-text {
		position: absolute;
		left: 0;
		right: 0;
		top: -10px;
		text-align: center;
		cursor: pointer;

		> span {
			padding: 0 4px;
			background-color: white;
			color: $textbox-button-color;
		}
	}

	&__fade {
		background: linear-gradient(to bottom, rgba($white,0) 0%, rgba(white ,1) 75%);
		height: 40px;
		margin-top: -40px;
		position: relative;
	}
}