/**
 * Minutes
 *
 * Description: Some styling and overrides for the Minutes component.
 * -----------------------------------------------------------------------------
 */
 .minutes-edit-form {

  .minutes-header {
    margin-bottom: -5px;
  }

  .minutes-btn-back {
    margin-right: 10px;
  }

  .form-group {
    margin-left: -15px;
    margin-right: -15px;

    &:hover {
      padding-top: 0px;
    }
  }
 }

 .minutes-btn-forward {
   font-size: 18px;
   padding: 0px;
   margin-right: -15px;
 }

 .media-body .minutes-details {
   padding-bottom: 5px;
   padding-top: 5px;
 }

 .minutes-filter-buttons {
   border-bottom: 1px solid darkgray;
   > * {
     float: right;
     border: 1px solid darkgray;
     padding: 8px;
     margin: 5px 0px 5px 5px;
   }
 }


.minutes-view {
    background-color: $white;
    padding: $margin-standard;

    .minutes-view--header {
        @include clearfix;
        font-size: em(16px);
        margin-bottom: em(10px, 16px);

        .minutes-view--header-date {
            float: right;
        }
    }

    .table {
        border-bottom: 1px solid $border-color-base;

        > thead {
            > tr {
                > th {
                    font-size: em(14px) !important;
                }

                &.minutes-table--filled-header {
                    background-color: #eaf0f5;
                }
            }
        }

        > tbody {
            > tr {
                > td {
                    &.minutes-table--label {
                        width: 20%;
						background-color: #f8f9fa;
						padding-top: 14px;
						vertical-align: unset;
                    }

                    &.minutes-table--text {
                        width: 80%;
					}
                }
            }
		}
    }
}

.minutes-table {
	&__signatories {
		&--image {
			width: 50px;
		}

		&--name {
			width: 20%;
		}

		&--ssn {
			width: 20%
		}
	}
}

td.minutes-table__signatories--image {
	padding: 0 !important;
}
