.meeting-table {
	.meeting-date {
		width: 100px;
	}
	.meeting-time {
		width: 90px;
	}
}

.message-table {
	.message-date {
		width: 100px;
	}
}
