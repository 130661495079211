.share-capital {
	&__list-header {
		display: flex;
		flex-direction: row;
		padding-left: 8px;
		margin-bottom: 5px;
		color: $invono-grey;

		&__share-capital {
			width: 180px;
			padding-right: 8px;
		}

		&__num-of-shares,
		&__quota {
			width: 180px;
			padding-right: 8px;
		}
	}
}

.share-distribution-a {
	&__list-header {
		display: flex;
		flex-direction: row;
		padding-left: 8px;
		margin-bottom: 5px;
		color: $invono-grey;

		&__shareholder {
			width: 220px;
			padding-right: 8px;
		}

		&__class-of-shares {
			width: 200px;
			padding-right: 8px;
		}

		&__price-per-share {
			width: 100px;
			padding-right: 8px;
		}

		&__total {
			width: 100px;
			padding-right: 8px;
		}
		&__transaction-restrictions {
			padding-right: 8px;
		}
	}

	&__list-body {
		&__shareholder {
			width: 220px;
		}

		&__class-of-shares {
			width: 200px;
		}

		&__price-per-share {
			width: 100px;
		}

		&__total {
			width: 100px;
		}
		&__transaction-restrictions {
			flex:1
		}
	}
}
