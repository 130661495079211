.button {

	&--w50 {
		width: 80px;
	}

	&--mar-right {
		margin-right: 10px;
	}
}

.btn {
	position: relative;

	&--mw85 {
		min-width: 80px;
	}

	&--spinner {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		font-size: 20px;
	}
}

.btn-group {
	+ .btn, + .btn-group {
		margin-left: 15px;
	}

	.btn + .btn {
		margin-left: 5px;
	}

	&__label {
		display: inline-block;
		float: left;
		padding: 9px 0;
		font-size: 13px;
		line-height: 1.42857;
		vertical-align: middle;
		margin-right: 5px;
	}

	&.align-center {
		display: block;

		> .btn {
			float: none;
		}
	}
}

.status-button-wrapper {
	display: flex;
	height: 40px;
	margin-bottom: -15px;
	z-index: 100;
}
