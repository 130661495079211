/**
 * Loader (Spinkit)
 *
 * Description: Style for the loading screen (shown before React has been initialized).
 * See: http://tobiasahlin.com/spinkit/
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Loading screen
 * 2.0 - Spinkit animations
 * 3.0 - App loading indicator
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Loading screen
 * -----------------------------------------------------------------------------
 */

#loading-screen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $body-bg;
	z-index: 100;
	opacity: 1;
	transition: opacity 0.3s ease-in;

	&.is-fading-out {
		opacity: 0;
	}

	.spinner {
		position: relative;
		top: 50%;
		@include transform(translateY(-50%));
		margin: 0 auto;
		width: 50px;
		height: 40px;
		text-align: center;
		font-size: 10px;
	}

	.spinner > div {
		background-color: $invono-accent-color;
		height: 100%;
		width: 6px;
		display: inline-block;

		-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
		animation: sk-stretchdelay 1.2s infinite ease-in-out;
	}

	.spinner .rect2 {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}

	.spinner .rect3 {
		-webkit-animation-delay: -1.0s;
		animation-delay: -1.0s;
	}

	.spinner .rect4 {
		-webkit-animation-delay: -0.9s;
		animation-delay: -0.9s;
	}

	.spinner .rect5 {
		-webkit-animation-delay: -0.8s;
		animation-delay: -0.8s;
	}
}



/**
 * 2.0 - Spinkit animations
 * -----------------------------------------------------------------------------
 */

@-webkit-keyframes sk-stretchdelay {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1.0);
	}
}

@keyframes sk-stretchdelay {
	0%, 40%, 100% {
		@include transform(scaleY(0.4));
	}
	20% {
		@include transform(scaleY(1.0));
	}
}



/**
 * 3.0 - App loading indicator
 * -----------------------------------------------------------------------------
 */

.app-loading-indicator {
	$font-size: 30px;

	position: fixed;
	top: em(-70px, $font-size);
	left: 50%;
	font-size: em($font-size);
	line-height: 1;
	width: em(50px, $font-size);
	height: em(50px, $font-size);
	padding: em(10px, $font-size);
	background-color: #fff;
	margin-left: em(-25px, $font-size);
	z-index: 999;
	border-radius: 50%;
	box-shadow: 0 0 em(10px, $font-size) 0 rgba(0,0,0,0.5);
	transition: top 0.3s ease-in;

	@media (min-width: 992px) {
		margin-left: em(110px, $font-size);
	}

	&.is-loading {
		top: em(10px, $font-size);
	}
}

.activity-bar {
	display: block;
	line-height: 40px;
	width: 40px;
	height: 40px;
	float: left;
	margin: 0 5px;
	vertical-align: middle;
	font-size: 23px;
	text-align: center;
	color: $invono-accent-color;
}
