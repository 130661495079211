.documents {

	&__list-header {
		display: flex;
		flex-direction: row;
		padding-left: 15px;
		margin-bottom: 5px;
		color: $invono-grey;

		&__date {
			width: 90px;
			padding-left: 5px;
		}

		&__ext {
			padding-left: 5px;
			margin-left: 1px;
			width: 50px;
		}

		&__title {
			padding-left: 5px;
			margin-left: 1px;
			flex-grow: 1
		}

		&__button {
			padding-left: 5px;
			margin-left: 1px;
			width: 97px;
		}
	}

}
