/**
 * BankId
 *
 * Description: Styles for BankId
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - BankId information text
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - BankId information text
 * -----------------------------------------------------------------------------
 */

.bankid-information-text {
	.bankid-icon img {
		position: relative;
		z-index: 10;
		width: 40px;
	}

	.bankid-status-text {
		position: relative;
		@include transition(background-color 2.4s);

		&.is-highlighted:before {
			$padding: -8px;
			z-index: 5;
			position: absolute;
			top: $padding;
			right: $padding;
			bottom: $padding;
			left: $padding;
			content: ' ';
			background-color: #fff191; 
		}

		p {
			position: relative;
			z-index: 10;
		}
	}

	&.well .bankid-status-text.is-highlighted:before {
		display: none;
	}
}