/**
 * Iframes
 *
 * Description: Styles to mnake iframes responsive.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Iframe
 * 2.0 - Iframe component
 * 3.0 - Responsive iframe
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Iframe
 * -----------------------------------------------------------------------------
 */

iframe {
	width: 100%;
	border: 0;
}


/**
 * 2.0 - Iframe component
 * -----------------------------------------------------------------------------
 */

.iframe {
	.iframe-loader {
		position: absolute;
		top: 70px;
		font-size: 30px;
		width: 100%;
		text-align: center;
		z-index: 2;
	}

	iframe {
		position: relative;
		z-index: 5;
	}
}



/**
* 3.0 - Responsive iframe
 * -----------------------------------------------------------------------------
 */

.responsive-iframe {
	position: relative;
	padding-bottom: 56.25%; // Aspect ratio 16:9: 56,25%; 4:3: 75%
	padding-top: 0;
	height: 0;
	overflow: hidden;

	&.responsive-iframe-format-4-3 {
		padding-bottom: 75%;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}