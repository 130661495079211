/** fadeInRight */
@-webkit-keyframes fadeInRight {
	from {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

/** fadeInLeft */
@-webkit-keyframes fadeInLeft {
	from {
    	opacity: 0;
	    -webkit-transform: translate3d(-100%, 0, 0);
	    transform: translate3d(-100%, 0, 0);
  	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.account-wizard {
	padding-top: 15px;
	padding-bottom: 25px;
	width: 100%;

	&__content {

	}

	&__header {
		height: 146px;
		overflow: hidden;

		&--h90 {
			height: 90px;
		}
	}

	&__title {
		font-size: 16px;
		color: $font-color-invono;
		margin-bottom: 30px;
	}

	&__step {
		$width: 40px;
		background-color: $font-color-invono;
		color: $font-color-white;
		border-radius: 50%;
		width: $width;
		height: $width;
		line-height: $width;
		display: inline-block;
		text-align: center;
		font-weight: 700;
		margin-right: 10px;
	}

	&__body {
		height: 300px;
		overflow: hidden;

		&--h275 {
			height: 275px;
		}
	}

	&__footer {
		text-align: center;
		padding-top: 25px;

		.btn {
			margin: 0 4px;
		}
	}

	&--animated {
		-webkit-animation-duration: .5s;
		animation-duration: .5s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}

	&--fade-in-right {
		-webkit-animation-name: fadeInRight;
		animation-name: fadeInRight;
	}

	&--fade-in-left {
		-webkit-animation-name: fadeInLeft;
		animation-name: fadeInLeft;
	}

	&--fade-out-right {
		-webkit-animation-name: fadeOutRight;
		animation-name: fadeOutRight;
	}

	&--fade-out-left {
		-webkit-animation-name: fadeOutLeft;
		animation-name: fadeOutLeft;
	}
}
