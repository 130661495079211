$colWidth: 8.33%;
$cols: 12;
@mixin flexTableColsPercentage() {
	@while $cols > 0 {
		&--#{$cols} {
			width: ceil($colWidth * $cols);
		}
		$cols: $cols - 1;
	}
}

@mixin flexTableCols() {
	& {
		width: auto;
		@include flexTableColsPercentage();
	}

	&--w15 {
		width: 15px;
		min-width: 15px;
	}

	&--w50 {
		width: 50px;
		min-width: 50px;
	}

	&--w90 {
		width: 90px;
		min-width: 90px;
	}

	&--w180 {
		width: 180px;
		min-width: 180px;
	}

	&--percentage-value {
		width: 12%;
		min-width: 12%;
	}

	&--no-wrap {
		white-space: nowrap;
	}

	&--pad-no {
		padding: 0;
	}
}

.flex-table {
	width: 100%;
	table-layout: auto;
	border-collapse: collapse;
	margin-bottom: 15px;

	&__overflow {
		overflow-x: auto;
	}

	&__header-row {
		border-bottom: 1px solid $table-border-color;
	}

	&__header-col {
		padding: 6px 7px;
		font-weight: normal;
		color: $font-color-light;
		@include flexTableCols();
	}

	&__body-col {
		vertical-align: middle;
		padding: 6px 7px;
		height: 46px;
		@include flexTableCols();
	}

	&__body-row {
		background-color: $table-color-light;
		border-bottom: 1px solid $table-border-color;

		&--link {
			cursor: pointer;
		}

		&--active {
			background-color: $bg-color-active !important;
		}
	}

	&--striped {
		.flex-table__body-row:nth-of-type(odd) {
			background-color: $table-color-dark;
		}
	}
}
