.share-shareholders {
	&__owner {
		width: 200px;
		padding: 0 4px;
	}
	&__name {
		width: 250px;
		padding: 0 4px;
	}
	&__id {
		width: 200px;
		padding: 0 4px;
	}
	&__delete {
		flex: 1;
		justify-content: flex-end;
		display: flex;
		flex-direction: row;
		padding: 0 4px;
	}
	&__number {
		padding: 0 4px;
		width: 40px;
	}
}
