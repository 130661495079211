.layout {
	&--inline-block {
		display: inline-block;
	}
}

.i-layout-row {
	flex: 1;
	display: flex;
	flex-direction: row;
}

.i-layout-column {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;

	&--left {
		padding-right: 10px;
	}

	&--right {
		padding-left: 10px;
	}

	&--pad-lft-55 {
		padding-left: 55px
	}
}

.pad-all {
	padding: 15px;
}

.pad-hor {
	padding-left: 15px;
	padding-right: 15px;
}

.pad-hor-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.pad-ver {
	padding-top: 15px;
	padding-bottom: 15px;
}

.pad-ver-0 {
	padding-top: 0;
	padding-bottom: 0;
}

.pad-lft-0 {
	padding-left: 0 !important;
}

.pad-btm-0 {
	padding-bottom: 0 !important;
}

.mar-lft-20 {
	margin-left: 20px;
}

.mar-btm-0 {
	margin-bottom: 0;
}

.mar-top {
	margin-top: 15px;
}

.mar-top-0 {
	margin-top: 0;
}
