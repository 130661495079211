/**
 * Base
 *
 * Description: Contains some base styling for some common html-elements.
 * -----------------------------------------------------------------------------
 */

// :focus-visible {
// 	outline: none;
// }

body.mce-content-body {
	margin: 10px;
	background: #fff;
}

#floating-top-right {
	z-index: 100 !important;
}

#content-container {
	max-width: 1400px;
	padding-bottom: 0px !important;
}

#page-title-wrapper {
	padding: 0 15px;
}

#page-title {
	position: fixed;
	z-index: 10;
	background-color: #ecf0f5;

	@media (max-width: 991px) {
		position: relative;
	}

	&.page-title--agenda {
		max-width: 1300px;
		width: calc(100% - 285px);
		padding-right: 0;
		margin: 0 16px 0 0;

		.page-title--inner {
			margin: 0 0 0 -15px;

			.page-header {
				margin-left: -2px;
			}
		}
	}
}

#navbar {
	z-index: 26 !important;
}

#page-content {
	padding-top: 69px !important;

	@media (max-width: 991px) {
		padding-top: 20px !important;
	}
}

#footer {
	padding-top: 8px !important;
	z-index: 20 !important;
}

#aside-container {
	z-index: 20 !important;
}

#aside {
	background-color: $white !important;
	padding-top: 0 !important;
}


.panel-body > [class^="col-"]:not(.pad-no){
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
}

.row.narrow {
	margin-left: -3px !important;
	margin-right: -3px !important;

	[class^="col-"]:not(.pad-no), .col-md-5, .col-md-2 {
		padding-left: 3px !important;
		padding-right: 3px !important;
	}
}

img {
	border: 0 none;
	height: auto;
	max-width: 100%;
	vertical-align: middle;
}

ul.plain {
	padding: 0;
	list-style: none;

	> li {
		display: inline-block;
		list-style: none;
		margin: 2px 1px;
	}
}
