/**
 * Button
 *
 * Description: Style for custom buttons.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Buttons
 * 2.0 - Form button group
 * 3.0 - Button extensions
 * 4.0 - Toggle Switch Button
 * 5.0 - Dropdown Button
 * 6.0 - More action
 * 7.0 - Save As button
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Buttons
 * -----------------------------------------------------------------------------
 */

.btn-green {
	@include btn-variant(white, $invono-accent-color);
}

.btn-tag {
	@include btn-variant($btn-tag-standard-color, $btn-tag-standard-bg);
	padding-left: 6px;
	padding-right: 6px;

	&.selected {
		@include btn-variant($btn-tag-selected-color, $btn-tag-selected-bg);
	}
}

.btn-panel-header, .btn-accordion-header {
	padding-top: 10px;
	padding-bottom: 10px;

	&-basic, &, &:focus, &.disabled:hover, &:disabled:hover{
        background-color: $font-color-invono-light;
        border-color: $font-color-white;
        color: $font-color-white;
    }

	&:hover, &:active, &.active,
	.open > .dropdown-toggle#{&}{
		background-color: darken($font-color-invono-light, 9%);
		border-color: $font-color-white;
		color: $font-color-white;
	}
}

.btn-accordion-header {
	padding-top: 5px;
	padding-bottom: 5px;
}

.btn-account-wizard {
	min-width: 100px;

	&-basic, &, &:focus, &.disabled:hover, &:disabled:hover{
        background-color: $font-color-white;
        border-color: $font-color-invono;
        color: $font-color-invono;
    }

	&:hover, &:active, &.active,
	.open > .dropdown-toggle#{&}{
		background-color: darken($font-color-invono-light, 9%);
		border-color: darken($font-color-invono-light, 7%);
		color: $font-color-white;
	}
}


/**
 * 2.0 - Form button group
 * -----------------------------------------------------------------------------
 */

.form-button-group {
	margin-left: -5px;

	.btn, .btn-group {
		margin-left: 5px;
	}

	+ hr {
		margin-top: 0;
		border-color: $greyLight;
	}
}


/**
 * 3.0 - Button extensions
 * -----------------------------------------------------------------------------
 */
.btn {
	padding-top: 8px;
	padding-bottom: 8px;

	> .fa {
		padding: 0;

		&.fa-lg {
			vertical-align: -4%;
			font-size: em(15px);

			&.fa-plus {
				vertical-align: middle;
			}
		}
	}

	&.btn-wider {
		padding-right: 60px;
		padding-left: 60px;
	}

	&.dropdown-toggle {
		box-shadow: none !important;
		-webkit-box-shadow: none !important;
	}
}



/**
 * 4.0 - Toggle Switch Button
 * -----------------------------------------------------------------------------
 */
 .btn-toggle-switch {
	 margin-top: 0;
	 padding: 4px 3px 4px 15px;
	 border-color: $toggle-switch-container-bg;
	 color: $toggle-switch-container-bg;
	 border-radius: 11.5px;
	 line-height: 13px;
	 position: relative;
	 top: -4px;
	 left: -16px;
	 background-color: $white;

	 &:hover, &:focus {
		 color: $toggle-switch-container-bg;
	 }

	 &.active {
		 border-color: $toggle-switch-container-bg-checked;
		 color: $toggle-switch-container-bg-checked;
		 box-shadow: none !important;
	 }
 }



 /**
  * 5.0 - Dropdown Button
  * -----------------------------------------------------------------------------
  */
.dropdown-menu {
	.fa-li {
		position: relative;
		left: auto;
		top: auto;
		width: 26px;
	}
}

/**
 * 6.0 - More action
 * -----------------------------------------------------------------------------
 */
.btn-more-action {
	width: 36px;
}

/**
 * 7.0 - Save As button
 * -----------------------------------------------------------------------------
 */
 .btn-save-as {
	 min-width: 205px;
 }
