.help-block {
	display: block;
	margin-top: 10px;
}

.control-label {
	&--flex {
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: space-between;
	}

	&__title {
		display: flex;
	}

	&__link {
		display: flex;
		justify-content: flex-end;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}

input[type="checkbox"].form-control,
input[type="radio"].form-control {
	top: 4px;
	height: auto;
}

.checkbox {
	&--adjust-align {
		input[type="checkbox"],
		input[type="radio"] {
			margin-left: 0;
			margin-top: 0;
		}
	}
}

.form-control-static {
	&--mar-hor {
		margin-left: 10px;
		margin-right: 10px;
	}
}

.input-group-addon {
	&--border-none {
		border: none;
	}
}

.personal-notes-control {
	background-color: $bg-color-personal-notes !important;
}

.meeting-notes-control {
	background-color: #f9f9f9 !important;
}

.form-group {
	&--mar-btm-0 {
		margin-bottom: 0;
	}
	// needed to lower the margins when inputfields are last element
	&--mar-btm-10 {
		margin-bottom: 10px;
	}
}

.has-feedback[class*="col-md"] {
	.form-control {
		padding-right: 85px;
	}

	.form-control-feedback {
		width: auto;
		text-align: right;
		right: 20px;
		top: 24px;
	}
}
