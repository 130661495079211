.i-accordion {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;

	&__section {
		margin: 2px 0;

		&--disabled {
			.i-accordion__title-wrapper {
				background-color: $font-color-invono-light;
				cursor: not-allowed;

				.i-accordion__title {
					color: rgba($font-color-white, .5);
				}
			}

			.i-accordion__button-wrapper {
				background-color: $font-color-invono-light;
			}
		}
	}

	$heading-height: 50px;
	&__heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: $heading-height;
		cursor: pointer;
	}

	&__title-wrapper {
		background-color: $font-color-invono;
		flex: 1;
		height: 100%;
	}

	&__button-wrapper {
		background-color: $font-color-invono;
		text-align: center;
		height: 100%;
		min-width: 130px;
		padding: 6px 20px 8px;
	}

	&__title {
		font-size: 16px;
		color: $font-color-white;
		margin: 0;
		line-height: $heading-height;
		padding: 0 15px;
	}
}
