.i-navbar {
	display: flex;
	height: 40px;
	background-color: $bg-color-navbar;
	box-shadow: 0 0 2px 0 rgba(0,0,0,.2);
	justify-content: space-between;
	align-items: center;

	&__right {
		display: flex;
		align-items: center;
	}

	&__left {
		display: flex;
		align-items: center;
	}

	&__company-selector {

	}

	&__user {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex: 1;
		height: 40px;
		max-width: 300px;
		border-right: 1px solid rgba(165,165,165,0.3);
		padding: 0 15px;

		&:hover {
			background-color: rgba(165,165,165,0.2);
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 25px;
		border-right: 1px solid rgba(165,165,165,0.3);
		width: 50px;
		height: 40px;

		&:hover {
			background-color: rgba(165,165,165,0.2);
		}
	}
}
