.dropzone {
	&--disabled {
		cursor: not-allowed !important;
	}

	&.dropzone--disabled {
		&:hover {
			.dz-text {
				color: $invono-grey;
			}
		}
	}

	@media screen and (min-width: 768px) {
		padding: 10px;

		.dz-message {
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
}
