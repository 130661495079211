/**
 * Typography
 *
 * Description: Some overrides.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Classes
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Classes
 * -----------------------------------------------------------------------------
 */

.text-muted {
	color: $invono-grey;
}

.text-warning {
	color: $warning-bg;
}

.bg-success{@include bg-variant($success-color, $success-bg)}
.bg-warning{@include bg-variant($warning-color, $warning-bg)}
.bg-danger{@include bg-variant($danger-color, $danger-bg)}
