


/**
 * 2.0 - Navbar
 * -----------------------------------------------------------------------------
 */

#navbar {

	.navbar-company-header {
		margin: 0;
		padding: 10px 0 0 10px;
	}

	.brand-icon {
		padding: 10px;
	}

	.navbar-dropdown {
		display: table-cell;
		padding: 0 12px;
		vertical-align: middle;
		height: $navbar-height;
		color: $navbar-color;
		@include transition(all .4s);
		border-color: transparent;

		&:hover {
			background-color: rgba(0,0,0,0.05);
			box-shadow: none;
		}
	}

	.navbar-external-link, .aside-toggle {
		.fa {
			font-size: 24px;
		}
	}

	.navbar-dropdown--placeholder {
		display: block;
		padding: 5px 10px;
		display: block;
		clear: both;
		font-weight: normal;
		line-height: 1.42857143;
		white-space: nowrap;
	}

	.open > .navbar-dropdown {
		background-color: rgba(0,0,0,0.05);
		box-shadow: none;
	}

	.username {
		max-width: 225px;
	}
}



/**
 * 3.0 - Nav tabs
 * -----------------------------------------------------------------------------
 */

.nav-tabs {
	&.nav-tabs-blue {
		> li:not(.active) {
			> a {
				background-color: $white;
				opacity: 1;
			}
		}
		> li.active {
			> a {
				background-color: $blueLight;
				opacity: 1;
			}
		}
	}
}



/**
 * 4.0 - Misc
 * -----------------------------------------------------------------------------
 */

 hr.menu-separator {
	 border: 1px solid #4b4b4b;
	 margin: 30px 0 40px 15px;
 }

 .mainnav-toggle {
	 display: none;
	 position: absolute;
	 color: #fff;
	 top: 9px;
	 right: 12px;
	 font-size: 16px;
	 z-index: 20;

	&:hover, &:focus, &:active {
		color: #fff;
	}

	.mainnav-sm & {
		top: 49px;
		right: 16px;
	}

	@media (max-width: 991px) {
		display: block;
	}

	@media (max-width: 767px) {
		top: 8px !important;
	}
}

@media (max-width: 767px) {
	.navbar-top-links .tgl-menu-btn {
		right: auto;
		left: 104px;
		top: -104%;
		width: 40px;
		z-index: 28;
	}
}

.navbar-top-links {
	height: 40px;

	.head-list li a {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
}

.dropdown-menu {
	padding: 9px 0;

	&.panel-default {
		padding: 0;
	}

	li .fa {
		min-width: 14px;
	}
}
