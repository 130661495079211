input[type="checkbox"].form-control {
	width: auto;
}

.i-checkbox {

	&__control {
		margin-top: 1px;

		&[type="checkbox"], &[type="radio"] {
			margin-top: 0px;
			margin-left: 0px !important;

		}

		+ label {
			padding-left: 20px;
		}
	}
}
