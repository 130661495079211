/**
 * Shares
 *
 * Description: Some styles for shares.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Share circle
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Share circle
 * -----------------------------------------------------------------------------
 */

.share-circle-holder {
	@include clearfix();
	display: flex;
	align-items: center;
	justify-content: center;


	.share-circle {
		float: left;
		width: 112px;
		height: 112px;
		padding: 0 15px;
		border-radius: 50%;
		background-color: $invono-accent-color;
		color: $white;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		+ .share-circle {
			margin-left: -10px;
		}

		&.share-circle--b {
			background-color: rgba($greyLight, 0.5);
			color: $greyDark;
		}

		&.share-circle--large {
			width: 154px;
			height: 154px;
		}

		.share-circle-label {
			font-weight: 700;
			font-size: 22px;
			margin-bottom: 0;
		}
		&.share-circle--large .share-circle-label {
			font-size: 24px;
		}

		.share-circle-text {
			font-size: 12px;
		}
	}
}
