.table {
    .col-status {
        width: 10px !important;
        padding-left: 0;
        padding-right: 0;
        font-size: 1px !important;

        &--green {
            background-color: $success-bg;
        }

        &--red {
            background-color: $danger-bg;
        }
    }

	> thead {
		> tr {
			> th {
				border-bottom-width: 1px;
			}
		}
	}

    > tbody {
        > tr {
            > td {
                vertical-align: middle;

				&.table-label {
					width: 35%;

					label {
						font-weight: bold;
					}
				}
            }
        }
    }

	&--activities {
		> thead {
			> tr {
				> th {
					font-size: em(13px) !important;
					color: $font-color-light !important;
					font-weight: 400 !important;
					border-bottom-color: $white !important;

					&.function {
						width: 75%;
					}
				}
			}
		}

		> tbody {
			> tr {
				> td {
					color: $font-color-mid !important;
					border-bottom: 1px solid $white !important;

					&.function {
						width: 75%;
					}

					&.date {
						white-space: nowrap;
					}
				}
			}
		}
	}

	&--white-border {
		> tbody {
			> tr {
				> td {
					border-top: 0;
					border-bottom: 1px solid $font-color-white;
				}
			}
		}
	}
}
