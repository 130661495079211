.i-panel {
	display: flex;
	flex-direction: column;
	background-color: unquote("hsl(var(--background))");
	border: 1px solid unquote("hsl(var(--border-edge))");
	margin-bottom: 16px;
	box-shadow: 0px 2px 4px rgba(0,0,0,.1);
	border-radius: unquote("var(--radius)");

	ul {
		list-style: disc;
		
		li {
			margin-inline-start: 30px;
		}
	}

	&--mar-bot-0 {
		margin-bottom: 0px;
	}

	&__heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 15px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.07);
		height: 50px;
		
		&--transaction-accordio {
			padding-bottom: 16px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.07);
		}

		&--big {
			padding: 0;
			background-color: $font-color-invono-light;
			height: auto;

			.i-panel__title-wrapper {
				padding: 5px 15px;
				background-color: $font-color-invono;
				display: flex;
				flex: 1 1 100%;
				flex-direction: column;
				justify-content: center;
			}

			.i-panel__title-wrapper--row-space-between {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			.i-panel__button-wrapper {
				text-align: center;
				height: 100%;
				width: 25%;
				padding: 20px 30px;
				min-width: 250px;
			}

			.i-panel__title {
				font-size: 2.46em;
				line-height: 1.2em;
				color: $font-color-white;
				min-height: 40px;
				flex: none;
			}

			.i-panel__title__text-muted {
				color: rgba($font-color-white, .75);
			}

			.i-panel__subtitle {
				color: $font-color-white;
			}
		}

		&--pad-hor-0 {
			padding-left: 0;
			padding-right: 0;
		}

		&--green {
			background-color: $bg-color-pnel-green;

			> .i-panel__title {
				color: $white;
			}
		}

		&--blue {
			background-color: $bg-color-pnel-blue;

			> .i-panel__title {
				color: $white;
			}
		}
	}

	&__title {
		font-weight: 600;
		font-size: 1.2em;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 0;
		margin-bottom: 0;
		flex: 1;

		> span {
			font-size: medium;
			vertical-align: middle;
		}
	}

	&__body {
		padding-top: 16px;			/*//!----*/
		padding-bottom: 14px;		/*//!----*/

		&--narrow {
			padding-left: 5px;
			padding-right: 5px;
			padding-bottom: 10px;
			padding-top: 10px;

			.form-group[class*="col-"] {
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		&--pad-hor {
			padding-left: 16px;		/*//!----*/
			padding-right: 16px;	/*//!----*/

			&-x2 {
				padding-left: 30px;
				padding-right: 30px;
			}
		}

		&--pad-vert-x2 {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		&--pad-right-60 {
			padding-left: 15px;
			padding-right: 60px;
		}

		&--pad-vert-no {
			padding-top: 0;
			padding-bottom: 0;
		}

		&--pad-top-half {
			padding-top: 7.5px;
		}

		&--pad-btm-0 {
			padding-bottom: 0;
		}

		&--pad-top-0 {
			padding-top: 0;
		}

		&--mar-btm-0 {
			margin-bottom: 0;
		}

		&--height-50 {
			height: 50px;
		}

		&--expandable {
			padding-top: 0;
			padding-bottom: 0;
			overflow-y: hidden;
			max-height: 0;
			transition: max-height 0.5s ease-in-out;

			&-open {
				max-height: attr(data-height);
				max-height: 650px;
			}
		}

		&--mh400 {
			min-height: 400px;
		}

		&--mh360 {
			min-height: 360px;
		}

		&--rte {
			ol, ul {
				padding-left: 15px;
			}
		}
	}

	&__footer {
		padding: 15px 15px 25px;
	}

	&__information {
		display: flex;
		flex-direction: column;
		margin: 10px 0;
	}

	&--darker-bg {
		background-color: $bg-color-panel-darker;
		margin: 0 -15px;
		padding: 20px 15px;
	}

	&--bordered {
		border: 1px solid $panel-border-color;

		&--top-no {
			border-top: none;
		}
	}

	&--border-bottom {
		border-bottom: 1px solid $panel-border-color;
	}

	&--white {
		background-color: $white;
	}

	&--transparent {
		background-color: transparent;
	}

	&--colorful-purple {
		background-color: $bg-color-panel-purple;
		color: $white;

		&-light {
			background-color: $bg-color-panel-purple-light;
			color: $white;
		}
	}

	&--colorful-yellow {
		background-color: $bg-color-panel-yellow;
		color: $white;

		&-light {
			background-color: $bg-color-panel-yellow-light;
			color: $white;
		}
	}

	&--mar-hor-10 {
		margin-left: 10px;
		margin-right: 10px;
	}

	&--mar-btm-0 {
		margin-bottom: 0;
	}

	&--state-button {
		height: 63px;

		.i-panel__body {
			padding-bottom: 7.5px;
		}
	}

	&--closed {
		display: none;
	}
}
