/**
 * Button
 *
 * Description: Style for custom filters.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Companies filter
 * -----------------------------------------------------------------------------
 */


 /**
  * 1.0 - Companies filter
  * 2.0 - Tags filter
  * -----------------------------------------------------------------------------
  */

 .companies-filter {
     float: right;
     padding-top: 7px;
 }


 /**
  * 2.0 - Tags filter
  * -----------------------------------------------------------------------------
  */

.tags-filter {
	label {
		margin-top: 5px;
	}

	.btn {
		margin-top: 0;
	}
}

.filter-form-more-action {
	margin-top: 3px;

	> .btn {
		padding-top: 8px;
		padding-bottom: 8px;
	}
}

.custom-tags-filter-wrapper {
	margin-bottom: 10px;

	&.col-md-12 {
		padding-left: 0;
		padding-right: 0;
	}
}
