/* IMPORTANT :
* ===================================================================================================
* DON'T EDIT ANY CODE HERE, JUST LEAVE IT.
*
* You may edit the default color scheme.
* You may override the variable value inside from _variables.SCSS using themes/invono/variables.
*
* If you make changes directly in the CSS and/ or SCSS-files from Nifty,
* it will be hard to update if I release an update. So I would recommend to create a new CSS/ SCSS-file for this purpose.
*/


@import "_variables";
@import "themes/invono/variables";
$main-compile: true;
$fa-font-path: "../fonts/fontawesome";

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&amp;subset=latin);

// Bootstrap is a requirement for Nifty
@import "vendors/bootstrap/bootstrap";
@import "vendors/fontawesome/font-awesome";
@import "vendors/dropzone/dropzone";
@import "vendors/react-select/default";
@import "vendors/magic-check/magic-check";
@import "vendors/rc-pagination/rc-pagination";

// We need this before our theme, because we use mixins from this file.
@import "nifty/_bs-overwrite";

// Set as default color scheme.
@import "themes/invono/_main";

// Import Nifty
@import "nifty/_layout";
@import "nifty/_navbar";
@import "nifty/_aside";
@import "nifty/_animations";
@import "nifty/_extras";
@import "nifty/_helper-classes";

@import "blocks/invono";
@import "blocks/base-layout";
@import "blocks/menu";
@import "blocks/navbar";
@import "blocks/activity-bar";
@import "blocks/scroll-container";
@import "blocks/shares";
@import "blocks/documents";
@import "blocks/list";
@import "blocks/dropzone";
@import "blocks/content";
@import "blocks/scroll-container";
@import "blocks/panel";
@import "blocks/permissions-editor";
@import "blocks/text";
@import "blocks/layout";
@import "blocks/table";
@import "blocks/account-wizard";
@import "blocks/expandable";
@import "blocks/checkbox";
@import "blocks/accordion";
@import "blocks/share-capital";
@import "blocks/share-types";
@import "blocks/toggle";
@import "blocks/form";
@import "blocks/share-overview";
@import "blocks/share-shareholders";
@import "blocks/box";
@import "blocks/button";
@import "blocks/flex-table";
@import "blocks/select";
@import "blocks/split";
@import "blocks/image-crop";
@import "blocks/textbox.scss";
@import "blocks/show-more-box";
// External libs
@import "vendor-custom/react-select";

@import "vendors/tiptap/editor.scss"