@keyframes hover-color {
  from {
    border-color: $magic-border-color;
  }

  to {
    border-color: $magic-main-color;
  }
}

.magic-radio,
.magic-checkbox {
  position: absolute;
  display: none;
}
.magic-radio[disabled],
.magic-checkbox[disabled] {
  cursor: not-allowed;
}

.input-group-addon{
    > .magic-radio + label, > .magic-checkbox + label {
        display: block;
    }
}

.magic-radio + label,
.magic-checkbox + label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin: 0;

  &:hover {
    &:before {
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: hover-color;
    }
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: $magic-checkbox-size;
    height: $magic-checkbox-size;
    content: '';
    border: 1px solid $magic-border-color;
  }
  &:after {
    position: absolute;
    display: none;
    content: '';
  }
}
.magic-radio + label{
    min-width: $magic-radio-size;
    min-height: $magic-radio-size;
    &:not(:empty){
        padding: 0 $magic-radio-size + 5px !important;
    }
}
.magic-checkbox + label{
    min-width: $magic-checkbox-size;
    min-height: $magic-checkbox-size;
    &:not(:empty){
        padding: 0 $magic-checkbox-size + 5px !important;
    }
}

.btn-group{
    .magic-radio + label, .magic-checkbox + label{
        vertical-align: baseline;
        min-height: 1em
    }
}


.magic-radio[disabled] + label,
.magic-checkbox[disabled] + label {
  cursor: not-allowed;
  opacity: .5;
  &:hover,
  &:before,
  &:after {
    cursor: not-allowed;
  }
  &:hover {
    &:before {
      animation-name: none;
    }
  }
}


.magic-radio:checked + label,
.magic-checkbox:checked + label {
  &:before {
    animation-name: none;
  }
  &:after {
    display: block;
  }
}
