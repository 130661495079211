.textbox {
	margin-bottom: 15px;

	&__text {
		padding-left: 15px;
		padding-right: 15px;

		p {
			display: none;

			&:first-child {
				display: block;
			}
		}

		&--open {
			p {
				display: block;
			}
		}
	}

	&__button {
		position: relative;
		height: 1px;
		margin: 15px 0 30px;
		border-top: 1px dashed $textbox-border-color;
	}

	&__button-text {
		position: absolute;
		left: 0;
		right: 0;
		top: -10px;
		text-align: center;
		cursor: pointer;

		> span {
			padding: 0 4px;
			background-color: $textbox-bg-button;
			color: $textbox-button-color;
		}
	}

	&--white {
		.textbox__button-text > span {
			background-color: $white;
		}
	}
}
