.period-indicator {
    text-align: center;
    line-height: 1;
    margin: 0 0 5px;

    .period-indicator--days {
        font-size: em(72px);
        font-weight: bold;
    }

    .period-indicator--text {
        font-size: em(16px);
    }
}
