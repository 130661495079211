/**
 * Modal
 *
 * Description: Some overrides for the Nifty modal.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Base
 * 2.0 - Fullscreen modal
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Base
 * -----------------------------------------------------------------------------
 */

body.ReactModal__Body--open {
	overflow: hidden;
}



/**
 * 2.0 - Fullscreen modal
 * -----------------------------------------------------------------------------
 */

.modal {
	.modal-dialog.modal-fullscreen {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 0;
		width: auto;

		.modal-content {
			height: 100%;
			overflow: auto;
		}
	}

	.modal-body {
		padding: 30px 15px 0;
		margin-bottom: 15px;
	}

	.modal-footer {
		//padding: 0 15px 30px;
		.btn + .btn, .btn-group + .btn {
			margin-left: 5px;
		}
	}
}

.modal-backdrop {
	.in {
		opacity: 0.4 !important;
	}
}

.modal-body, .modal-footer {
	//border-top: 0px;
	//text-align: left;
}

@mixin modal-content-center {
	.modal-content-center {
		display: block;
		margin: auto;
		width: 90%;
		text-align: center;
	}
}

.modal-content-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.modal-body, .modal-footer {
	@include modal-content-center;
}

.modal-sm {
	.modal-body .modal-content-center,
	.modal-footer .modal-content-center {
		width: 96%;
	}
}
