.shares {
	&__remaning-shares {
		display: flex;
		height: 34px;

		&__text {
			display: flex;
			align-items: center;
		}
	}
}
