

.user-management--access {

	.col--name {
		width: 250px;
	}

	.col--switch {
		width: 80px;
	}

	.level {
		&--one {
			background-color: white !important;
		}

		&--two {
			.col--name {
				padding-left: 20px;
			}
			background-color: #EAF0F5 !important;
		}

		&--three {
			.col--name {
				padding-left: 40px;
			}
			background-color: #EAF0F5 !important;
		}
	}

	.expandable-row {
		color: $invono-accent-color;
		font-weight: bold;
		cursor: pointer;

		.fa {
			font-weight: 900;
		}
	}

}

.btn {
	&.btn-inline {
		display: inline-block;
	}
}
