.expandable {
	max-height: 0;
	overflow: hidden;
	transition: max-height 1s ease-in-out;

	&--open {
		max-height: 999px;
	}

	&--no-hidden {
		overflow: visible;
	}
}
