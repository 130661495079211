/**
 * Timeline
 *
 * Description: Some overrides for the Nifty Timeline.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Timeline (overrides)
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Timeline (overrides)
 * -----------------------------------------------------------------------------
 */

.timeline-white {
	background-color: transparent !important;

	.timeline-label {
		background-color: #fff !important;

		&:after {
			border-right-color: #fff !important;
		}
	}
}
