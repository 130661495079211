/**
 * States
 *
 * Description: Common classes used for layout/display/states
 * Version: 2.0.0
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Visibillity states
 * -----------------------------------------------------------------------------
 */



/**
 * 1.0 - Visibillity states
 * -----------------------------------------------------------------------------
 */

.is-visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.is-visuallyhidden.is-focusable:active, .is-visuallyhidden.is-focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}
