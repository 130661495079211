/**
 * Mixins
 *
 * Description: Contains Sass mixins
 * Version: 2.0.3
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Clearfix
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Clearfix
 *
 * Description: For clearing floats
 * -----------------------------------------------------------------------------
 */

@mixin clearfix {
	&:before,
	&:after {
		display: table;
		content: "";
		line-height: 0;
	}
	&:after {
		clear: both;
	}
}
