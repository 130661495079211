
/* ASIDE */
/* ===================================== */
#container {
    #aside-container{
        padding: 0 20px;
        position: relative;
        top: 0;
        z-index: 3;
        .nano-content{
            position: static;
            outline: none
        }
    }
    #aside {
        .list-link li a:not(.btn){
            color: darken($aside-dark-color,18%);
            &:hover{
                color: $aside-dark-color;
            }
        }
        .badge-stat{
            color: $aside-dark-bg
        }
        .text-main{
            color: $aside-dark-color;
        }
    }
    &.aside-float.aside-in #aside-container{
        box-shadow: 0 0 5px 6px rgba(0,0,0,.15)
    }
}

#aside{
    background-color: $aside-dark-bg;
    color: $aside-dark-color;
    overflow: hidden;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translateZ(0);
    a:not(.btn):not(.btn-link){
        color: inherit;
    }
    &.aside-xs-in{
        max-height: none;
        overflow: hidden;
        margin-bottom: 70px;
    }
    .bord-all,
    .bord-top,
    .bord-btm,
    .bord-left,
    .bord-rgt,
    .bord-hor,
    .bord-ver,
    .list-divider{
        border-color: lighten($aside-dark-bg,3%);
    }
    .btn-link{
        color: $aside-dark-color;
    }
    .text-muted{
        color: lighten($aside-dark-bg,35%);
    }
    hr{
        border-color: rgba(0,0,0,0.15)
    }
}


@if $aside-tabs{

    /* Aside with tabs */
    /* ===================================== */
    #aside{
        #aside-container &{
            .nav-tabs{
                &.nav-justified > li{
                    display: table-cell;
                    width: 1%
                }
                li{
                    border-bottom: 1px solid darken($aside-dark-bg, 5%);
                    > a{
                        border-radius: 0;
                        border: 0;
                        background-color: $aside-dark-bg;
                        padding: .9em 0;
                        margin: 0;
                        > i{
                            font-size: 1.35em;
                            vertical-align: sub
                        }
                        &:before{
                            content: '';
                            display: block;
                            background: $state-active-bg;
                            height: 2px;
                            position: absolute;
                            bottom: -1px;
                            left: 50%;
                            right: 50%;
                            transition: left .05s, right .05s
                        }
                    }
                    &:first-child > a{
                        border-left: 0;
                    }
                    &:last-child > a{
                        border-right: 0;
                    }
                    &:not(.active) a{
                        opacity: .5;
                    }
                }
                .active{
                    a:before{
                        left: 0;
                        right: 0;
                        transition: left .15s, right .15s
                    }
                }
            }
            .tab-content{
                padding: 5px 0;
            }
        }
        #container.aside-bright &{
            .nav-tabs {
                li{
                    border-bottom: 1px solid darken($aside-bright-bg, 5%);
                    > a{
                        background-color: $aside-bright-bg
                    }
                }
            }
        }
    }
}


/* ASIDE - RESPONSIVE LAYOUT */
/* ===================================== */
@media (max-width: 760px){
    #container > div > #aside-container{
        top: $navbar-height
    }

    #container.aside-left.aside-in #aside-container{
        left: 0;
        right: auto;
    }
    #container.aside-left{
        #aside-container{
            left: 0 - $aside-width;
            right: auto;
        }
    }
    #container.aside-left.mainnav-sm #content-container,
    #container.aside-left.mainnav-sm #footer {
        padding-left: 0
    }
}

@media (min-width: 768px){
    #container.mainnav-sm.aside-left.aside-in{
        #aside-container{
            left: $mainnav-sm-width;
            right: auto
        }
        #content-container, #footer{
            padding-left: $mainnav-lg-width;
        }
    }
    div#container.mainnav-lg.aside-left.aside-in{
        #content-container, #footer{
            padding-left: $mainnav-lg-width;
            text-align: left
        }
    }
}



#aside{
    padding-top: $navbar-height;
    background-color: $aside-dark-bg;
    height: 100%;
}

#container{
    #aside-container{
        padding: 0;
        position: absolute;
        width: $aside-width;
        height: auto;
        left: auto;
        right: -$aside-width;
        top: 0;
        bottom: 0;
        min-height: 100vh;
        overflow: hidden;
        z-index: 3;
        .nano-content{
            position: absolute
        }
    }
    #content-container, #footer{
        padding-right: 0
    }
    &.mainnav-in{
        &:not(.slide) #footer, & #content-container{
            padding-right: 0
        }
    }
}

@media (min-width: 1024px){
    #container.aside-in{
        #aside{
            padding-top: $navbar-height;
        }
        &:not(.aside-float) #content-container{
            padding-right: $aside-width;
        }
        &.aside-left{
            &:not(.aside-float) #content-container, &:not(.aside-float) #footer{
                padding-left: $aside-width;
                padding-right: 0;
            }
        }
        &:not(.aside-float) #content-container, &:not(.aside-float) #footer{
            padding-right: $aside-width;
        }
        &.aside-left{
            &:not(.aside-float) #content-container, &:not(.aside-float) #footer{
                padding-left: $aside-width;
                padding-right: 0;
            }
            &.mainnav-lg{
                &:not(.aside-float) #content-container, &:not(.aside-float) #footer{
                    padding-left: $mainnav-lg-width+$aside-width;
                    padding-right: 0
                }
                #aside-container{
                    left: $mainnav-lg-width;
                    right: auto
                }
            }
            &.mainnav-sm{
                &:not(.aside-float) #content-container, &:not(.aside-float) #footer{
                    padding-left: $mainnav-sm-width+$aside-width;
                    padding-right: 0
                }
                #aside-container{
                    left: $mainnav-sm-width;
                    right: auto;
                }
            }
            &.mainnav-in{
                #aside-container{
                    left: $mainnav-lg-width;
                    right: auto;
                }
            }

        }
    }
}


/* ASIDE - TOGGLE HIDE/SHOW */
/* ===================================== */
#container{
    &.aside-in {
        #aside-container{
            left: auto;
            right: 0;
        }
        &.aside-left{
            &.mainnav-in{
                #aside-container{
                    left: $mainnav-lg-width;
                    right: auto;
                }
            }
        }

    }
}

@media (min-width: 768px){
    #container{
        &.aside-left #aside-container, &.aside-in.aside-left #aside-container{
            left: 0;
            right: auto
        }
        &.aside-left{
            &.mainnav-sm{
                #aside-container{
                    left: (0 - $aside-width) + $mainnav-sm-width;
                }
                &:not(.aside-float) #content-container, &:not(.aside-float) #footer{
                    padding-left: $mainnav-sm-width
                }
            }
            &.mainnav-out{
                &:not(.aside-in) #aside-container{
                    left: -$mainnav-lg-width;
                }
            }
        }
    }
}

/* ASIDE - FIXED POSITION */
/* ===================================== */
#container{
    &.aside-fixed{
        #aside-container{
            position: fixed;
        }
        #aside{
            height: 100%;
            .nano-content{
                position: absolute;
            }
        }
        &:not(.navbar-fixed){
            #aside-container.affix{
                top: 0 - $navbar-height;
                position: fixed;
                &-top{
                    top: 0;
                    position: absolute
                }
            }
        }

    }
}


/* ASIDE - BRIGHT COLOR THEMES */
/* ===================================== */
#container{
    &.aside-bright{
        #aside{
            background-color: $aside-bright-bg;
            border: 1px solid darken($body-bg, 5%);
            color: $aside-bright-color;
            @if lightness($mainnav-bg) >= 70%{
                border: 1px solid darken($body-bg, 5%);
            }
            @if lightness($body-bg) >= 95%{
                border: 1px solid darken($body-bg, 5%);
            }
            .badge-stat{
                color: $aside-bright-bg
            }
            .text-main{
                color: $body-main-color
            }
            .text-light{
                color: $body-color;
            }
            hr{
                border-color: $border-color-base
            }
            .bord-all,
            .bord-top,
            .bord-btm,
            .bord-lft,
            .bord-rgt,
            .bord-hor,
            .bord-ver,
            .list-divider{
                border-color: darken($body-bg, 7.5%);
            }
            .text-muted{
                color: $text-muted
            }
            .progress{
                background-color: darken($aside-bright-bg, 10%);
            }
            .list-link li a:not(.btn){
                color: lighten($aside-bright-color,5%);
                &:hover{
                    color: $aside-bright-color
                }
            }
            a:not(.btn){
                color: $aside-bright-color;
                &:hover, &:focus{
                    color: darken($aside-bright-color,10%);
                }
            }
            .btn-link{
                color: $aside-bright-color;
            }
        }

    }

}
