.toggle {
	display: none;

	&--on {
		display: block;
	}

	&--pos-rel {
		position: relative;
	}
}
