.status-bar {
	border-right: 3px solid #225e5e;
	text-align: right;
	padding: 5px 10px;

	h4 {
		margin-top: 0;
		margin-bottom: 5px;
		font-weight: 400;
	}

	&--minimal-vert-margins {
		padding: 0px 10px;

		h4 {
			margin-bottom: 0px;
		}
	}
}
