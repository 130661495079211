.v-box {
	display: flex;
	flex-direction: column;

	&--h-center {
		align-items: center;
	}

	&--v-center {
		vertical-align: middle;
	}

}

.h-box {
	display: flex;
	flex-direction: row;

	&--h-center {
		vertical-align: middle;
	}

	&--v-center {
		align-items: center;
	}

	&--v-right {
		align-items: flex-end;
	}
}

.box {
	&--v-center {
		margin-left: auto;
		margin-right: auto;
	}

	&--inline {
		display: inline-block;
	}
}

.f1-box {
	flex: 1;
}

.box-50x50 {
	width: 50px;
	height: 50px;
	line-height: 50px;
}

.box-w70 {
	width: 70px;
}
.box-w90{
	width: 90px;
}
