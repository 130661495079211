.dropdown-menu {
    &.fa-ul, .head-list.fa-ul {
        > li {
            > a {
				padding-left: 15px;
                padding-right: 15px;
            }
        }

        .fa-li {
    		position: relative;
    		left: auto;
    		top: auto;
    		width: 26px;
            padding-right: 6px;
    	}
    }

	&.has-input {
		padding-top: 0;
	}

	.input-wrapper {
		background-color: lighten($invono-grey, 30%);
		padding: 5px;

		input {
			height: 28px;
		}

		.btn {
			margin-top: 0;
		}
	}

	.dropdown-chosen {
		background-color: $blueLight;
		padding: 5px 10px;
		white-space: nowrap;

		h4 {
			font-size: 13px;
		}
	}

	&--duration {
		min-width: 50px;
	}

	&--status {
		min-width: 100px;

		> li {
			> a {
				color: $body-main-color;
			}
		}
	}

	&--save-as {
		min-width: 245px;
	}
}

.attendees-row--status.pull-right > .dropdown-menu {
	right: auto;
	left: 0;
}
