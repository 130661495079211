.cls-container {
	overflow-x: hidden;
	overflow-y: auto !important;

	.bg-img {
		background-image: url('../images/bg-helsingborg-3.jpg');
		position: fixed;
	}
	.cls-content {

		.panel-body {
			padding: 35px 60px;
		}

		.panel {
			width: 430px;
			min-height: 530px;
			background-color: white;
		}
	}

	.form-group.col-md-12 {
		padding-left: 0;
		padding-right: 0;
	}

	.logo img {
		width: 150px;
	}

	.cls-header-text {
		color: $invono-grey;
	}

	.btn-link {
		cursor: pointer;
		color: #337ab7;
	}

	.back-btn {
		position: absolute;
		bottom: 30px;
		text-align: left;
		color: $invono-grey;
		cursor: pointer;
	}

	.checkbox {
		display: inline-block;

		label {
			padding-right: 0 !important;
		}
	}

	.register-tick {
		color: $invono-accent-color;
	}

	.alert a, .alert a:hover {
		color: #fff;
		text-decoration: underline;
	}
}


@media (max-width: 767px) {
	.cls-container {
		.cls-content {
			.panel-body {
				padding: 35px 20px;
			}

			.panel {
				width: 90%;
				margin-left: 5%;
			}
		}
	}
}
