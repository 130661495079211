/**
 * Mail list
 *
 * Description: Some overrides for the Nifty mail list.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Mail list
 *   1.1 - Mail list (responsive)
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Mail list
 * -----------------------------------------------------------------------------
 */

.mail-list {
	background-color: $white;

	a,  a:focus {
		display: block !important;
	}
}


/**
 * 1.1 - Mail list (responsive)
 * -----------------------------------------------------------------------------
 */

@media (max-width: 991px) {
	.mail-subject {
		margin: 0 !important;
	}
}
