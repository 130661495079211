.i-activity-bar {
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
	padding: 0 16px;
	justify-content: space-between;

	&__logo {
		height: 22px;
	}
	&__spinner {

		font-size: 22px;
		margin-top: 5px;
		color: white;
	}
}
