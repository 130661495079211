/**
 * Functions
 *
 * Description: Contains Sass functions
 * Version: 1.1.0
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - PX to EM
 * 2.0 - Tint
 * 3.0 - Shade
 * 4.0 - Get font-size
 * -----------------------------------------------------------------------------
 */



/**
 * 1.0 - PX to EM
 *
 * Description: Converts px-values to em-values.
 * -----------------------------------------------------------------------------
 */

@function em($px, $base: $font-size-base) {
    @return ($px / $base) * 1em;
}



/**
 * 2.0 - Tint
 *
 * Description: Slightly lighten a color
 * Read more: http://sass-guidelin.es/#lightening-and-darkening-colors
 * -----------------------------------------------------------------------------
 */

@function tint($color, $percentage) {
	@return mix($color, white, $percentage);
}



/**
 * 3.0 - Shade
 *
 * Description: Slightly darken a color
 * Read more: http://sass-guidelin.es/#lightening-and-darkening-colors
 * -----------------------------------------------------------------------------
 */

@function shade($color, $percentage) {
	@return mix($color, black, $percentage);
}



/**
 * 4.0 - Get font-size
 *
 * Description: Returns a font-size (in pixels) from the font-size map.
 * -----------------------------------------------------------------------------
 */

@function getFontsize($sizeMap, $breakpoint) {
	@if map-has-key($sizeMap, $breakpoint) {
		@return nth(map-get($sizeMap, $breakpoint), 1);
	}

	@warn "Unknown `#{$breakpoint}` in $sizeMap.";
	@return null;
}