/**
 * Scroll pane
 *
 * Description: Some overrides for the Nifty modal.
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Base
 *   1.1 - Base (responsive)
 * 2.0 - Animations
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Base
 * -----------------------------------------------------------------------------
 */

.scroll-pane-holder, .scroll-pane-holder--agenda {
	position: fixed;
	height: calc(100% - 110px);
	margin: 0 15px 0 -15px;
	//overflow: hidden;
	max-width: 1300px;
    width: calc(100% - 285px);
	//z-index: 10;

	.mce-fullscreen & {
		z-index: 100;
	}

	&.scroll-pane-holder--with-fixed {
		margin-top: 40px;
		height: calc(100% - 150px);
	}

	.scroll-pane {
		position: relative;
		overflow-x: hidden;
	    overflow-y: auto;
		height: 100%;
		//z-index: 10;
		padding-bottom: 40px;

		&.col-md-6:after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: 70px;
			margin-left: -50px;
			width: 100px;
			height: 100px;
			line-height: 100px;
			vertical-align: middle;
			border-radius: 15px;
			background-color: rgba(0, 0, 0, 0.3);
			z-index: 30;
			text-align: center;
			animation: fadeOutScrollPane 0.7s ease 1.5s forwards;
		}
		&.col-md-6:before {
			@include fa-icon();
			content: $fa-var-angle-double-down;
			display: inline-block;
			animation: MoveUpDown 0.7s ease-in-out infinite alternate, fadeOutScrollPane 0.7s ease 1.5s forwards;
			position: absolute;
			left: 50%;
			margin-left: -13px;
			z-index: 40;
			margin-bottom: 95px;
			bottom: 0;
			color: #fff;
			font-size: 40px;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		&:last-child {
			//z-index: 15;
		}

		.scroll-pane--fixed {
			position: fixed;
			z-index: 40;
			right: calc(100% - 913px);
			margin-top: -40px;

			&.scroll-pane--fixed-bottom {
				margin-top: 0;
				bottom: 0;
				width: calc(50% - 368px);
			}

			&.scroll-pane--filled {
				border-top: 15px solid #ecf0f5;
				background-color: $blueLight;
				padding: 7.5px;
			}

			@media (max-width: 1585px) {
				right: calc(50% - 120px);
			}

			&.scroll-pane--full-width {
				right: 22px;
			}

			&.scroll-pane--minutes {
				right: calc(100% - 1563px);

				@media (max-width: 1585px) {
					right: calc(50% - 770px);
				}

				@media (max-width: 1280px) {
					right: calc(50% - 620px);
				}
			}
		}

		+ .scroll-pane .scroll-pane--fixed {
			right: calc(100% - 1563px);

			@media (max-width: 1585px) {
				right: 22px;
			}

			&.scroll-pane--full-width {
				right: 22px;
			}
		}
	}
}

.scroll-pane-holder--agenda {
	margin-top: 2px;
	margin-left: 0;
	height: calc(100% - 150px);

	.scroll-pane-holder {
		height: calc(100% - 150px);
	}
}

.no-scroll-pane {
	margin: 0 -15px 0 -15px;
}

.buttons-wihout-scroll {
	margin-top: -37px;

	&.layout-right {
		margin-right: -7px;
	}
}

.scroll-pane-holder-single-col {
	padding-top: 40px;
}

#agenda-edit-form .scroll-pane--fixed {
	.layout-left .btn-link {
		margin-left: 0px;
		padding-left: 0;
	}
}


/**
 * 1.1 - Base (responsive)
 * -----------------------------------------------------------------------------
 */
@media (max-width: 991px) {
	.scroll-pane-holder.scroll-pane-holder--with-fixed, .scroll-pane-holder--agenda.scroll-pane-holder--with-fixed {
		position: relative;
		height: auto;
		margin: 0;
		width: 100%;
		overflow: auto;
	}
	.scroll-pane-holder .scroll-pane, .scroll-pane-holder--agenda .scroll-pane {
		height: auto;
		overflow: auto;
	}

	.scroll-pane.col-md-6 {
		&:before, &:after {
			display: none !important;
		}
	}

	.scroll-pane + .scroll-pane {
		margin-top: 30px;
	}
}



/**
 * 2.0 - Animations
 * -----------------------------------------------------------------------------
 */

@keyframes MoveUpDown {
	0% { transform: translateY(-10px); }
	100% { transform: translateY(10px); }
}
@-webkit-keyframes MoveUpDown {
	0% { bottom: -10px; }
	100% { bottom: 10px; }
}

@keyframes fadeOutScrollPane {
	0% { opacity: 1; }
	99% { opacity: 0; }
	100% { visibility: hidden; z-index: -1; opacity: 0; }
}
@-webkit-keyframes fadeOutScrollPane {
	0% { opacity: 1; }
	99% { opacity: 0; }
	100% { visibility: hidden; z-index: -1; opacity: 0; }
}
