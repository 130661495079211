.menu {
	display: flex;
	width: 240px;
	flex-direction: column;
	background-color: #1F3239;
	z-index: 100;

	&__header {
		display: flex;
		height: 40px;
		margin-top: 3px;
		margin-bottom: 10px;
		overflow: hidden;
		background-color: #1F3239;

	}

	&__tick {
		height: 20px;
		padding: 0 15px;
	}

	&__loading {
		line-height: 40px;
		font-size: 20px;
		padding: 0 15px;

		color: $invono-accent-color;
	}

	&__company-name {
		padding: 20px 0 20px 15px;
	}

	&__user-area {
		padding: 10px 0 10px 0;
	}

	&__company-area {
		background-color: #273E45;
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-bottom: 70px;
	}

	&__arrow-left {
	  width: 0;
	  height: 0;
	  border-top: 10px solid transparent;
	  border-bottom: 10px solid transparent;
	  border-right:10px solid $bg-color-body;

		align-self: center;
	}

	&__company-name {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		&__text {
			margin-left: 20px;
		}

	}

	&__body {
		display: flex;
		flex: 1;

		&__list{
			list-style-type: none;
			padding-left: 0;
			display: flex;
			flex-direction: column;
			flex: 1;
			margin-bottom: 0px;

			&--hidden {
				display: none;
			}

			&--item {
				display: flex;
				flex-direction: column;
				padding: 0;

			    .arrow {
			        float: right;
			        line-height: 1.42857
			    }
			    .arrow:before {
			        content: '';
			        border-style: solid;
			        border-width: 0.1em 0.1em 0 0;
			        display: inline-block;
			        height: 0.4em;
			        left: 0;
			        position: relative;
			        top: 0;
			        width: 0.4em;
			        @include transform(rotate(45deg));
			    }

			    &.text--menu--active > a > .arrow:before{
			        @include transform(rotate(135deg));
			    }
			}

			&__link {
				/*display: block;*/
				display: flex !important;
				justify-content: space-between;
				margin-bottom: 15px;
			}
		}

		//margin-bottom: 20px;
		//margin-top: 30px;

		.level {
			&--one {
				> span {
					color: $font-color-invono;
					font-size: 16px;
					margin: 0 15px 15px;
					line-height: 35px;
					border-bottom: 1px solid rgba(165,165,165,0.3);

				}
			}

			&--two {
				margin: 0 15px;
			}

			&--three {
				margin: 0 20px;
			}
		}
	}
}
