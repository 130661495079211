.scroll-container {
	display: flex;
	flex: 1 1 0;
	flex-direction: column;
	min-height: 1px;
	overflow: hidden;
	position: relative;



	&__body {
		min-height: 1px;
    flex: 1 1 auto;
		overflow-y: auto;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__tracker {

	}

}
