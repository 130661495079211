.recharts-responsive-container {
    padding-top: 30px;
}

.recharts-yAxis .recharts-cartesian-axis-tick {
    display: none;
}

.recharts-scroll {
    width: 100%;
    height: 250px;
    overflow: hidden;
    overflow-x: auto;
}
