// Displaying image and controls on the profile page
.imagecrop {
	display: flex;
	flex-direction: row;

	&__view {
		flex-shrink: 0;
		width: 180px;
		align-self: center;
		text-align: center;

		&--fill {
			width: 100%;
			flex-shrink: 1;
			flex-grow: 1;
		}
	}

	&__controls {
		align-items: center;
		flex-direction: column-reverse;
		display: flex;
		justify-content: space-between;
		padding-left: 10px;

		&__buttons {
			align-self: flex-start;
			display: flex;
			flex-direction: column;
		}

		&__button {
			margin: 3px 0;
			width: 70px;
		}

		&__button:nth-last-child(1) {
			margin-bottom: 0;
		}
	}

	&__spinner {
		&--h-180 {
			height: 180px;
			line-height: 180px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.imagecrop {
		flex-direction: column;

		&__view {
			align-self: center;
			flex: 1;
			max-width: auto;
		}

		&__controls {
			flex-direction: column;
			padding: 10px 0 0 0;

			&__buttons {
				flex-direction: row;
				justify-content: space-evenly;
				padding-top: 5px;
				width: 100%;
			}

			&__button:nth-last-child(1) {
				margin: 2px 0;
			}
		}
	}
  }

// Image crop modal customization
.modal-image-crop {
	padding: 0;
	margin: 0;
}

.modal-body-image-crop {
	margin: 15px;
	padding: 0;
}

.modal-image-crop:before {
	margin-left: 0;
}

.modal-dialog-image-crop {
	min-width: auto;
	margin: 15px;
}

@media screen and (min-width: 768px) {
	.modal-dialog-image-crop {
		width: auto;
	}
}
