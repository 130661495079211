.initials--card {
    display: inline-block;
    background-color: #D8D8D8;
    min-width: 50px;
    min-height: 50px;
    font-weight: bold;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    color: #B7B7B7;
	float: left;
}
