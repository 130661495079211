.subscription-item-list {
    margin-bottom: 15px;

    .subscription-item {
        padding: 5px 0;
        @include clearfix;

        .subscription-item--title {
            font-size: em(16px);
            display: inline-block;
        }

        .subscription-item--price {
            font-size: em(16px);
            float: right;
        }

        .subscription-item--vat {
            text-align: right;
            margin-top: -5px;
        }
    }
}
